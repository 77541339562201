import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// @ts-ignore
import commands from '../../../assets/commands.json';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {
  public commandList = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.commandList = commands;
  }
}
